// Klavika Light Condensed
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensed.eot');
    src: url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensed.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensed.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensed.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensedItalic.eot');
    src: url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensedItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensedItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-light-condensed/Klavika-LightCondensedItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
// Klavika Condensed
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-condensed/Klavika-Condensed.eot');
    src: url('#{$klavika-font-path}/klavika-condensed/Klavika-Condensed.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-condensed/Klavika-Condensed.woff') format("woff"), url('#{$klavika-font-path}/klavika-condensed/Klavika-Condensed.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-condensed/Klavika-CondensedItalic.eot');
    src: url('#{$klavika-font-path}/klavika-condensed/Klavika-CondensedItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-condensed/Klavika-CondensedItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-condensed/Klavika-CondensedItalic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
// Klavika Medium Condensed
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensed.eot');
    src: url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensed.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensed.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensed.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensedItalic.eot');
    src: url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensedItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensedItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-medium-condensed/Klavika-MediumCondensedItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
// Klavika Bold Condensed
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensed.eot');
    src: url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensed.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensed.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensed.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika Condensed";
    src: url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensedItalic.eot');
    src: url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensedItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensedItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-bold-condensed/Klavika-BoldCondensedItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
// Klavika Light
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-light/Klavika-Light.eot');
    src: url('#{$klavika-font-path}/klavika-light/Klavika-Light.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-light/Klavika-Light.woff') format("woff"),
         url('#{$klavika-font-path}/klavika-light/Klavika-Light.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-light/Klavika-LightItalic.eot');
    src: url('#{$klavika-font-path}/klavika-light/Klavika-LightItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-light/Klavika-LightItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-light/Klavika-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
// Klavika Regular
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-regular/Klavika-Regular.eot');
    src: url('#{$klavika-font-path}/klavika-regular/Klavika-Regular.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-regular/Klavika-Regular.woff') format("woff"), url('#{$klavika-font-path}/klavika-regular/Klavika-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-regular/Klavika-RegularItalic.eot');
    src: url('#{$klavika-font-path}/klavika-regular/Klavika-RegularItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-regular/Klavika-RegularItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-regular/Klavika-RegularItalic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
// Klavika Medium
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-medium/Klavika-Medium.eot');
    src: url('#{$klavika-font-path}/klavika-medium/Klavika-Medium.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-medium/Klavika-Medium.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-medium/Klavika-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-medium/Klavika-MediumItalic.eot');
    src: url('#{$klavika-font-path}/klavika-medium/Klavika-MediumItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-medium/Klavika-MediumItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-medium/Klavika-MediumItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
// Klavika Bold
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-bold/Klavika-Bold.eot');
    src: url('#{$klavika-font-path}/klavika-bold/Klavika-Bold.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-bold/Klavika-Bold.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-bold/Klavika-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Klavika";
    src: url('#{$klavika-font-path}/klavika-bold/Klavika-BoldItalic.eot');
    src: url('#{$klavika-font-path}/klavika-bold/Klavika-BoldItalic.eot#iefix') format("embedded-opentype"), url('#{$klavika-font-path}/klavika-bold/Klavika-BoldItalic.woff') format("woff"),
        url('#{$klavika-font-path}/klavika-bold/Klavika-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
// JP
@font-face {
    font-family: "ProJP";
    src: local("ヒラギノ角ゴ Pro");
    font-stretch: condensed;
    font-size: 10%;
    font-display: swap;
}
@font-face {
    font-family: "Meiryo";
    src: local("Meiryo");
    font-stretch: condensed;
    font-size: 10%;
    font-display: swap;
}
@font-face {
    font-family: "Osaka";
    src: local("Osaka");
    font-stretch: condensed;
    font-size: 10%;
    font-display: swap;
}
@font-face {
    font-family: "Hiragino";
    src: local("Hiragino Kaku Gothic Pro");
    font-stretch: condensed;
    font-size: 10%;
    font-display: swap;
}